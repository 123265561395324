jQuery.fn.center = function () { //used the centre something in the middle of the screen
    this.css("position", "absolute");
    this.css("top", ($(window).height() - this.height()) / 2 + $(window).scrollTop() + "px");
    this.css("left", ($(window).width() - this.width()) / 2 + $(window).scrollLeft() + "px");
    return this;
};


//Bind keyup functions to text inputs and textareas for the text counter
$(document).ready( function () {
  $('input[type="text"]').keyup(function() {
    var text_length = $(this).val().length;
    var text_max = $(this).data('parsleyMaxlength');
    var text_remaining = text_max - text_length;
    $(this).siblings('.count_message').html(text_remaining + ' characters remaining');
  });
} );

$(document).ready( function () {
  $('input[type="number"]').keyup(function() {
    var text_length = $(this).val().length;
    var text_max = $(this).data('parsleyMaxlength');
    var text_remaining = text_max - text_length;
    $(this).siblings('.count_message').html(text_remaining + ' characters remaining');
  });
} );

$(document).ready( function () {
  $('textarea').keyup(function() {
    var text_length = $(this).val().length;
    var text_max = $(this).data('parsleyMaxlength');
    var text_remaining = text_max - text_length;
    $(this).siblings('.count_message').html(text_remaining + ' characters remaining');
  });
} );

$(document).ready(function() {
  $('.Year-of-birth-datepicker').datepicker({
      format: 'yyyy',
      autoclose:true,
      startDate: '-100y',
      endDate: '+0y',
      minViewMode: 'years'
  });
 });

$(document).ready(function() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });
 });

$(document).ready(function(){
  $('.phonenumbermask').inputmask("999999999[999]",{greedy: false,showMaskOnHover: false, clearMaskOnLostFocus: true,showMaskOnFocus: false,skipOptionalPartCharacter:true,autoUnmask:true});
  $('.emailmask').inputmask("email");
  $('.postcodemask').inputmask('Regex', { regex: "[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?\s?[0-9][a-zA-Z]{2},\i" });  
});
